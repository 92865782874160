// src/data/businesses.js
const businesses = [
  {
    name: "Apartment Sittaima",
    logo: "apartmentsittaima.jpg",
    link: "https://www.apartmentsittaima.com",
    description: "Apartment Sittaima specializes in modern, well-designed residential spaces that cater to the growing demand for urban living in Nepal. With a commitment to quality construction and contemporary amenities, Apartment Sittaima provides comfortable, stylish homes for individuals and families alike.",
  },
  {
    name: "AtoZJewellery",
    logo: "atozjewellery.jpg",
    link: "https://www.atozjewellery.com/",
    description: "AtozJewelry is a dynamic multivendor eCommerce platform that brings together diverse jewelry brands and artisans under one virtual roof. Offering a wide selection of exquisite pieces from around the world, AtozJewelry connects customers with unique designs, ranging from classic to contemporary styles. ",
  },
  {
    name: "NAFA",
    logo: "path_to_logo4.jpg",
    link: "https://hillbirdgroup.com",
    description: "Nepal Farming and Agricultural Company is committed to advancing sustainable farming practices across Nepal. With a focus on eco-friendly techniques and innovative agricultural solutions, the company aims to boost productivity and promote food security while preserving the environment for future generations.",
  },
  {
    name: "Hill Bird Travel and Tours",
    logo: "path_to_logo4.jpg",
    link: "https://hillbirdgroup.com",
    description: "Hill Bird Travel and Tours is a leading travel agency dedicated to providing unforgettable travel experiences. Offering a range of services from adventure tours to cultural expeditions, we specialize in curating personalized travel packages across Nepal and beyond. With a commitment to customer satisfaction, we ensure that each journey is seamless, safe, and memorable.",
  },
  {
    name: "Hill Bird Housing and Developers",
    logo: "HillBirdHousing.jpg",
    link: "https://hillbirdgroup.com",
    description: "Hill Bird Housing and Developers Pvt. Ltd focuses on building premium residential and commercial properties. We are committed to designing and developing spaces that not only meet modern living standards but also contribute to sustainable urban development. With a focus on quality construction and customer-centric solutions, we aim to deliver value through every project.",
  },
  {
    name: "Hill Bird Multipurpose",
    logo: "HillBirdMultipurpose.jpg",
    link: "https://hillbirdgroup.com",
    description: "Hill Bird Multipurpose Pvt. Ltd is a versatile enterprise with investments in diverse sectors including retail, services, and agriculture. By fostering innovation and leveraging market opportunities, we deliver high-quality products and services that cater to the evolving needs of our clients and partners. Our commitment to excellence and sustainability drives our business forward.",
  },
];

export default businesses;
